import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UrlList from './UrlList'; // Import the new component
import './App.css';

const urls = [
  {
    url: "https://www.yourworldoftext.com/~GuglioIsStupid",
    path: "wot"
  },
  {
    url: "https://discord.gg/ehY5gMMPW8",
    path: "discord"
  },
  // help me i love teto
  {
    url: "https://x.com/mexoz1346/status/1810084350870044705",
    path: "discpfp1"
  },
  {
    url: "https://x.com/jlyt1494056573/status/1824997531753070925",
    path: "discpfp2"
  },
  {
    url: "https://x.com/pancakehime012/status/1826681377024327885",
    path: "discpfp3"
  },
  {
    url: "https://x.com/ErinArtista/status/1805470169478693173",
    path: "discpfp4"
  },
  {
    url: "https://atena-fumei.itch.io/mesmerizer-fangame",
    path: "discpfp5"
  },
  {
    url: "https://x.com/amia2255/status/1848257617753423893",
    path: "discpfp6"
  },
  {
    url: "https://x.com/re1_ka111/status/1849806343143838077",
    path: "discpfp7"
  },
  {
    url: "https://x.com/koutya_cat/status/1818723554835546559?t=mibCAra-JORXzKmhcjqDSQ&s=19",
    path: "discpfp8"
  }
];

function ExternalRedirect({ url }) {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
}

function App() {
  return (
    <Router>
      <Routes>
        {urls.map(({ path, url }, index) => (
          <Route key={index} path={`/${path}`} element={<ExternalRedirect url={url} />} />
        ))}
        <Route path="/list_urls" element={<UrlList urls={urls} />} /> {}
        <Route path="/list" element={<UrlList urls={urls} />} /> {}
      </Routes>
    </Router>
  );
}

export default App;
